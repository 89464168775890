import React from 'react'
import styled, { css } from 'styled-components'
import uniqueId from 'lodash/uniqueId'
import { useLocation } from 'react-router'
import loadable from '@loadable/component'

const Tabs = loadable(() => import(/* webpackChunkName: "Tabs", webpackPrefetch: true */ '../../../../components/Tabs'))
const TabsItem = loadable(() =>
  import(/* webpackChunkName: "TabsItem", webpackPrefetch: true */ '../../../../components/TabsItem'),
)

const Wrapper = styled.div`
  ${(props) =>
    props.hide &&
    props.hide.desktop &&
    css`
      @media (max-width: ${props.theme.desktop.breakPoint + 'px'}) {
        display: none;
      }
    `}
  ${(props) =>
    props.hide &&
    props.hide.tablet &&
    css`
      @media (min-width: ${props.theme.mobile.breakPoint + 'px'}) and (max-width: ${props.theme.tablet.breakPoint +
        'px'}) {
        display: none;
      }
    `}
  ${(props) =>
    props.hide &&
    props.hide.mobile &&
    css`
      @media (max-width: ${props.theme.mobile.breakPoint + 'px'}) {
        display: none;
      }
    `}
`

const StyledTabsItem = styled(TabsItem)`
  padding: 0 20px !important;
`

const BlockTabs = ({ data, loading, ...props }) => {
  const currentLocation = useLocation()
  const pathName = currentLocation && currentLocation.pathname.replaceAll(' ', '%20')
  let tabsItems = data && data.items
  let defaultValue = data && data.defaultValue
  let hide = data && data.hide

  if (data && data.locations) {
    data.locations.forEach((location) => {
      if (location.pathName === pathName) {
        tabsItems = location.items
        defaultValue = pathName
        hide = location.hide
      }
    })
  }
  if (!tabsItems) {
    return false
  }
  return (
    <Wrapper hide={hide} {...props}>
      <Tabs variant="scrollable" scrollButtons="auto" value={defaultValue}>
        {tabsItems.map((item) => (
          <StyledTabsItem nowrap="true" id={uniqueId()} label={item.label} value={item.value} />
        ))}
      </Tabs>
    </Wrapper>
  )
}

export default BlockTabs
